<template>
    <div class="footer">
        <div class="container">
            <a-row :gutter="20">
                <a-col :lg="{ span: 9 }" :xs="{ span: 24 }">
                    <ul>
                        <li><img src='../../assets/img/logo.png' /></li>
                        <li>CANADA</li>
                        <li>adress：2149 CONSTANCE DR, OAKVILLE, ONTARIO, CANADA</li>
                        <li>phone：365-992-6897</li>
                        <li>email：zhanglililarry@gmail.com</li>
                    </ul>
                </a-col>
                <a-col :lg="{ span: 10 }" :xs="{ span: 24 }">
                    <!-- <ul>
                        <li>product</li>
                        <li>
                            <router-link to="/index">
                                检尺机</router-link>
                        </li> -->
                        <!-- <li>
                            <router-link to="/counter">
                                {{ $t('Footer_text.counter') }}</router-link>
                        </li> -->
                        <!-- <li>
                            <router-link to="/Tensor1min">{{ $t('Header_text.machine_dropdown_2') }}
                            </router-link>
                        </li> -->
                    <!-- </ul> -->
                    <ul>
                        <li><img src='../../assets/img/logo.png' /></li>
                        <li>New Zealand</li>
                        <li>adress：94 Jeffreys Road Fendalton Christchurch 8052 New Zealand</li>
                        <li>phone：+64 21 618 961</li>
                        <li>email：service@crux.co.nz</li>
                    </ul>
                </a-col>
                <!-- <a-col :lg="{ span: 4 }" :xs="{ span: 15, offset: 4}" style="margin: 10px;">
                    <img style="width: 100%; height: 100%" src="../../assets/img/app.jpg" /> -->
                    <!-- <ul>
                        <li>
                            <a-tooltip placement="right">
                                <template #content><img
                                        style="width:150px;height:150px"
                                        src="@/assets/img/app.jpg"> </template>
                                {{ $t('Footer_text.subscribe') }}
                            </a-tooltip>
                        </li>
                        <li>
                            <a-tooltip placement="right">
                                <template #content><img
                                        style="width:150px;height:150px"
                                        src="@/assets/img/QRCode.jpg">
                                </template>
                                {{ $t('Footer_text.miniprogram') }}
                            </a-tooltip>
                        </li>
                        <li>
                            <a-tooltip placement="right">
                                <template #content><img
                                        style="width:150px;height:150px"
                                        src="@/assets/img/miniprogramme.jpg">
                                </template>
                                {{ $t('Footer_text.appName') }}
                            </a-tooltip>
                        </li>
                    </ul> -->
                <!-- </a-col> -->
                <!-- <a-col :span="6"><div class="grid-content bg-purple" /></a-col> -->
            </a-row>
            <!-- <a-row justify="start" style="padding-bottom:15px">
                <a-divider style="margin:10px 0"/>

                <a-col :lg="{ span: 24 }" :xs="{ span: 22 }">
                    
                </a-col>
            </a-row>  -->
        </div>
    </div>
</template>
<style scoped lang="scss">
@media screen and (min-width: 300px) {
    .container {
        width: 380px;
        margin: 0 auto;
    }
}
@media screen and (min-width: 768px) {
    .container {
        width: 600px;
        margin: 0 auto;
    }
}
@media screen and (min-width: 1200px) {
    .container {
        width: 1200px;
        margin: 0 auto;
    }
}
.footer {
    position: relative;
    bottom: 0px;
    // height: 300px;
    background-color: #272727;

}

.a-row {
    margin-bottom: 20px;
}

.a-row:last-child {
    margin-bottom: 0;
}

.a-col {
    border-radius: 4px;
}

.a-col:nth-child(2) {
    li:not(:first-child):hover {
        cursor: pointer;
        color: white;
    }
}

.a-col:last-child {
    li {
        color: #9fa3a6;
    }

    li:hover {
        cursor: pointer;
        color: white;
    }
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

ul {
    margin-top: 50px;
    list-style: none;

    li {
        height: 30px;
        color: #9fa3a6;
        text-align: left;
        font-size: 12px;
    }

    li:first-child {
        color: white;
        // font-size: 16px;
        font-weight: 500
    }

    img {
        // width: 100%;
        height: 100%;
    }
}

a {
    text-decoration: none;
    color: #9fa3a6;
    cursor: pointer;
}

span {
    color: #9fa3a6;
    margin-bottom: 10px;
}
</style>