<template>
    <div class="header">
      <div class="pc_header">
        <a-row type="flex" align-item="center">
          <a-col :lg="{ span: 1, offset: 3 }" :sm="{ span: 3, offset: 2 }"
            :xs="{ span: 22, offset: 2 }"><span>
              <router-link to="/"><img src="@/assets/img/logo.png" /></router-link>
            </span></a-col>
        </a-row>
      </div>
    </div>
  </template>
  <script>
  export default {
    components: {
        // Footer,
    },
    data() {
      return {
        // current: ['mail'],
      };
    },
  };
  </script>
  <style lang="scss" scoped>
  .header {
    width: 99%;
    z-index: 99;
    margin-top: 20px;
    font-size: 16px;
    // position: absolute;
  }
  img {
    position: absolute;
    // width: 100%;
    height: 100%;
  }
  
  @media screen and (min-width: 300px) {
    .pc_header {
      display: none;
    }
  
    .phone_header {
      display: block;
  
    }
  }
  
  @media screen and (min-width: 768px) {
    .pc_header {
      display: block;
    }
  
    .phone_header {
      display: none;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .pc_header {
      display: block;
    }
  
    .phone_header {
      display: none;
    }
  }
  
  .phone_header {
    text-align: justify;
    padding: 10px;
    // font-size: 24px;
  }
  
  .ant-row {
    // margin-bottom: 20px;
    color: rgb(101, 101, 101);
    height: 50px;
    line-height: 50px;
    text-align: left;
  }
  
  .a-row:last-child {
    margin-bottom: 0;
  }
  
  .a-col {
    // border-radius: 4px;
    height: 100%;
    // line-height: 100%;
  
    img {
      position: absolute;
      // width: 100%;
      height: 100%;
    }
  }
  
  .grid-content {
    // border-radius: 4px;
    min-height: 36px;
  }
  
  a {
    text-decoration: none;
    color: rgb(101, 101, 101);
    display: block;
    height: 100%;
  
  }
  
  .a-dropdown {
    color: rgb(101, 101, 101);
    font-size: 16px;
    line-height: inherit;
  }
  
  .example-showcase .a-dropdown+.a-dropdown {
    color: rgb(101, 101, 101);
  
    margin-left: 15px;
  }
  
  .example-showcase .a-dropdown-link {
    cursor: pointer;
    color: var(--a-color-primary);
    display: flex;
    align-items: center;
  }
  
  .example-showcase .a-dropdown-link {
    cursor: pointer;
    color: var(--a-color-primary);
    display: flex;
    align-items: center;
  }
  .machine_title{
    font-size: 16px;
    margin: 20px;
  }.a-dropdown-item{
    margin: 20px;
    text-align: left;
    font-size: 16px;
  }
  .a-dropdown-item-link{
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 1px solid #c4bfbf;
    text-align: left;
  }
  .d_title{
    display: block;
  }
  .d_title2{
    display: block;
  }
  .list{
      width: 140px;
      text-align: left;
      background-color:rgb(255, 255, 255);
      display: none;
      ul{
        list-style: none;
        // margin-left: 0px;
        padding: 0px;
        li{
          padding: 0px;
          // margin-bottom: 10px;
          text-align: center;
        }
      }
      // left: 50%;
      margin-left: -40px;
      // max-height: 0;
      position: absolute;
      // top: 30px;
      // z-index: 0;
      // -moz-transition: all 0.3s ease-in-out;
      // -webkit-transition: all 0.3s ease-in-out;
      // -o-transition: all 0.3s ease-in-out;
      // -ms-transition: all 0.3s ease-in-out;
      // transition: all 0.3s ease;
      // opacity: 0;
      // filter: alpha(opacity=0);
      // -moz-transform: scale(0);
      // -o-moz-transform: scale(0);
      // -o-moz-transform: scale(0);
      // -webkit-moz-transform: scale(0);
      // transform: scale(0);
  }
  .list_2{
    width:280px;
    display: none;
    position: absolute;
    text-align: left;
    margin-left: -40px;
    background-color: rgb(255, 255, 255);
  }
  .list:hover{
    display: block;
  }
  .d_title:hover + .list{
      display: block;
  }
  .list_2:hover{
    display: flex;
  }
  .d_title2:hover + .list_2{
    
    display: flex;
  }
  
  </style>