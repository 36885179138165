import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// Vue.config.productionTip = false;
// import './assets/css/all.css'
// import './assets/css/iconfont.css'
createApp(App).use(store).use(router).use(Antd).mount("#app");
