<template>
  <div class="header">
        <Header></Header>
  </div>
  <div class="wrap"></div>
  <div class="main">
          <a-row >
             <a-col :span="24" class="main_title">
              Introduction
             </a-col>
             <a-col class="main_content" :lg="{ span: 14, offset: 5}"
                        :sm="{ span: 18, offset: 3 }" :xs="{ span: 20, offset: 2 }">
                        Tensor Scaler creatively uses computer vision technology to solve log scaling problems. You can quickly determine the volume and diameter of each log by taking a photo with Tensor Scaler of the timber, whether in a pile, on a truck or in a container.

                        The sophisticated AI algorithms ensure that the accuracy of measurement exceeds the manual operation.

                        Tensor Scaler is the quickest solution for log scaling. It only takes a few minutes to measure hundreds of logs.

                        In addition, it can automatically recognize the QR code on the cross section of the wood and establish an identity label for each piece of wood

              </a-col> 
              <a-col :span="24">
                  <a-row >
                  <a-col class="main_title" :span="24">
                     Usage
                  </a-col>
                  <a-col  class="main_content" :lg="{ span: 14, offset: 5 }"
                        :sm="{ span: 18, offset: 3 }" :xs="{ span: 20, offset: 2 }">
                      <img style="width: 100%; height: 100%" src='../assets/img/usage.jpg'  />{{ j }}
                  </a-col> 
                </a-row>
                <a-row  >
                  <a-col class="main_title" :span="24">
                     Technical Specification
                  </a-col>
                  <a-col class="main_content" :lg="{ span: 14, offset: 5 }"
                        :sm="{ span: 18, offset: 3 }" :xs="{ span: 20, offset: 2 }">
                      <img style="width: 100%; height: 100%" src='../assets/img/technical.jpg'  />{{ j }}
                  </a-col> 
                </a-row>
                <a-row  >
                  <a-col class="main_title" :span="24">
                      Application Scenario
                  </a-col>
                  <a-col  class="main_content" :lg="{ span: 14, offset: 5 }"
                        :sm="{ span: 18, offset: 3 }" :xs="{ span: 20, offset: 2 }">
                      <img style="width: 100%; height: 100%" src='../assets/img/application.jpg'  />{{ j }}
                  </a-col> 
                  <a-col  class="main_content" :lg="{ span: 14, offset: 5 }"
                        :sm="{ span: 18, offset: 3 }" :xs="{ span: 20, offset: 2 }" style="margin-top: 10px;">
                      <img style="width: 100%; height: 100%" src='../assets/img/application2.jpg'  />{{ j }}
                  </a-col> 
                </a-row>
                <a-row >
                  <a-col class="main_title" :span="24">
                     Results
                  </a-col>
                  <a-col class="main_content" :lg="{ span: 14, offset: 5 }"
                        :sm="{ span: 18, offset: 3 }" :xs="{ span: 20, offset: 2 }">
                      <img style="width: 100%; height: 100%" src='../assets/img/result.jpg'  />{{ j }}
                  </a-col> 
                </a-row>
              </a-col>
              <a-col class="main_title" :span="24" style="margin-top: 150px;">
                Benefits
                </a-col>
                <a-col v-for="(item,index) in advantageIntroduce" :key="index" :lg="{ span:4, offset: 3 }"
                        :sm="{ span: 18, offset: 3 }" :xs="{ span: 9, offset: 1 }">
                   <div class="advantage_introduce">
                     {{ item.title }}<br/>{{ item.detail }}
                   </div>
                </a-col>
          </a-row>
      </div>
      <Footer></Footer>
</template>
<script>
import Header from '../components/Header/header.vue';
import Footer from '../components/Footer/footer.vue';
export default {
  components:{
    Header,
    Footer,
  },
  data(){
    return{
      content:[
        {title:'Usage',img:['../assets/img/usage.jpg']},
        {title:'Technical Specification',img:['../assets/img/technical.jpg']},
        {title:'Application Scenario',img:['../assets/img/application.jpg','../assets/img/application2.jpg']},
        {title:'Results',img:['../assets/img/result.jpg']},
        
      ],
      advantageIntroduce:[
        {title:'High Accuracy',detail:'The millimeter level measurement error can be guaranteed.The measurement accuracy can reach or exceed the level of manual operation'},
        {title:'Time Efficiency',detail:'The measurement time of 800 logs is less than 30s, and the efficiency can be increased dozens to hundreds of times compared with manual operation'},
        {title:'Easy to Use',detail:'Without special learning and training, you can get started quickly and operate smoothly'},
        {title:'Cost Efficiency',detail:'Greatly reduce the demand for manpower, and alleviate the shortage of personnel, the difficulty of recruitment'},
        {title:'Fair Trade',detail:'The cloud permanently stores the measurement results and original data, which can be shared with other parties, effectively avoiding the subjective deviation of manual ruler'},
        {title:'Digitization',detail:'Establish an identification label for each piece of wood by combining QR code recognition. Output digital inspection results, form reports, and make the data clear at a glance'},
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
// .warp{
//   top:'50px';
//   background-image: url('../assets/img/8ab9d37284b07af560a710c3c929df8.jpg');
// }
@media screen and (min-width: 300px) {
    .header {
        width: 100%;
        position: absolute;
    }

    .wrap {
        // top: 0px;
        font-size: 32px;
        height: 200px;
        background-size: 250%;

    }

    .offset-3 {

        // width: 100%;
        // height: 400px;
        // overflow: hidden;
        img {
            max-width: 100%;
            // min-width: 100%;
            max-height: 100%;
            // max-height: 100%;
            // margin:auto
            // overflow:hidden
        }
    }
}

@media screen and (min-width: 768px) {
    .wrap {
        font-size: 54px;
        height: 600px;
    }
    .header {
        width: 100%;
        position: relative;
    }
    .offset-3 {

        // height: 300px;
        // overflow: hidden;
        img {
            max-width: 100%;
            // min-width: 100%;
            min-height: 100%;
            max-height: 100%;
            // margin:auto
            // overflow:hidden
        }
    }
}

@media screen and (min-width: 1200px) {
    .wrap {
        font-size: 54px;
        height: 800px;
        background-size: auto 800px;

    }
    .header {
        width: 100%;
        position: relative;
    }
    .offset-3 {
        height: 300px;

        // overflow: hidden;
        img {
            max-width: 100%;
            // min-width: 100%;
            min-height: 100%;
            max-height: 100%;
            // margin:auto
            // overflow:hidden
        }
    }
}
.main{
  margin-bottom: 20px;
  font-size: 14px;
  margin-top: 50px;
  line-height: 1.5

}
.main_title{
  font-size: 23px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.advantage_introduce{
  padding: 15px;
  width:100%;
  height:120px;
  border: 1px solid #c4bfbf;
  margin-bottom: 10px;
  // box-shadow: 10px 10px 5px rgba(150, 150, 150, 0.9);
}
.wrap {
  top: 50px;
  // width: 100%;
  height: 800px;
  background-image: url('../assets/img/banner.jpg');
  // background-size: 100%;
  // line-height: 30vh;
  background-position: center;
  color: rgb(101, 101, 101);
  // font-size: 54px;
  // font-weight: 700;
  // background-size: auto 800px;
  background-repeat: no-repeat;
}
</style>