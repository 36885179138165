import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/index.vue";

const routes = [
  // {
  //   path: "/home",
  //   name: "home",
  //   component: Home,
  // },
  {
    path: "/",
    name: "home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    redirect: '/index',
      children:[
        {
          path: "index",
          name: "index",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "about" */ "../views/index.vue"),
        },
        {
          path:'solutions/timbeter',
          name:'Timbeter',
          component:()=>import('@/views/Solutions/timbeter.vue')
        },
        {
          path:'solutions/container',
          name:'Container',
          component:()=>import('@/views/Solutions/container.vue')
        },
        {
          path:'solutions/counter',
          name:'Container',
          component:()=>import('@/views/Solutions/counter.vue')
        },
      ]
  },
 
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
      
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
